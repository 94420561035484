import { Component, Vue } from "vue-property-decorator";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import IUpdateMixin from "@/mixins/interface/IUpdateMixin";

@Component({
  components: {},
})
export default class UpdateMixin extends Vue implements IUpdateMixin {
  protected name = "UpdateMixin.ts";

  onSubmit(item: any): void {
    this.updateItemAction({
      id: item.id,
      resource: this.resource,
      data: item,
      descriptionField: this.descriptionField,
    });
  }

  onAbort(item: any): void {
    this.$router.back();
  }

  updateItemAction(options: ICrudOptions): any {
    console.warn(
      "This is updateItemAction from UpdateMixin. Override this in your Update Component!"
    );
  }

  get descriptionField(): string {
    console.warn(
      "This is get descriptionField from UpdateMixin. Override this in your Update Component!"
    );
    return "id";
  }

  get resource(): string {
    console.warn(
      "This is get resource from UpdateMixin. Override this in your Update Component!"
    );
    return "";
  }

  protected init(): void {
    console.warn(
      "This is init() from UpdateMixin. Override this in your Update Component!"
    );
  }

  created(): void {
    console.info("init in UpdateMixin");
    this.init();
  }
}

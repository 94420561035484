import { State } from "./state";
import { GetterTree } from "vuex";

import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getIsRemoving,
  getIsSaving,
} from "@/store/crud/functions/getters";
import { CrudState } from "@/store/crud/State";

export function getImages(state: State): any {
  return state.images;
}

export function getIsImagesLoading(state: State): boolean {
  return state.isImagesLoading;
}

export function orderedPlantDetails(state: CrudState): any {
  return [
    { label: "modules.plant.id", value: state.dataItem.id },
    { label: "modules.plant.designation", value: state.dataItem.plant },
    { label: "modules.plant.strain", value: state.dataItem.strain },
    { label: "modules.plant.planted_as", value: state.dataItem.planted_as },
    { label: "modules.plant.planted_at", value: state.dataItem.planted_at },
    { label: "modules.plant.growth_stage", value: state.dataItem.growth_stage },
    {
      label: "modules.plant.growthstage_flowering_at",
      value: state.dataItem.growthstage_flowering_at,
    },
    { label: "modules.plant.pot_size", value: state.dataItem.pot_size },
    {
      label: "modules.plant.watering_milliliters",
      value: state.dataItem.watering_milliliters,
    },
    {
      label: "modules.plant.recent_watering_at",
      value: state.dataItem.recent_watering_at,
    },
    { label: "modules.plant.soil_type", value: state.dataItem.soil_type },
    {
      label: "modules.plant.used_fertilizer",
      value: state.dataItem.used_fertilizer,
    },
  ];
}

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getDeletedSuccess,
  getValidationErrors,
  getIsListLoading,
  getIsRemoving,
  getIsSaving,
  // additional
  orderedPlantDetails,
  getImages,
  getIsImagesLoading,
};

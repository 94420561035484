import { render, staticRenderFns } from "./PlantDetailCreate.vue?vue&type=template&id=21ff58c9&scoped=true"
import script from "./PlantDetailCreate.vue?vue&type=script&lang=ts"
export * from "./PlantDetailCreate.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ff58c9",
  null
  
)

export default component.exports
import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
} from "@/store/crud/functions/mutations";

export function SET_IMAGE_LIST(state: State, images: any) {
  state.images = images;
}

export function SET_IMAGES_LOADING(state: State, loading = true) {
  state.isImagesLoading = loading;
}

export default <MutationTree<State>>{
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_SAVING,
  SET_IS_REMOVING,
  SET_IMAGE_LIST,
  SET_IMAGES_LOADING,
};

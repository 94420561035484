
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ArchiveButton extends Vue {
  protected name = "ArchiveButton";

  @Prop({ required: false, default: false })
  public loading!: boolean;

  @Prop({ required: false, default: "is-small" })
  public size!: string;

  @Prop({ required: false, default: "is-warning is-light" })
  public type!: string;

  @Prop({ required: false, default: false })
  protected mustConfirm!: boolean;

  @Prop({ required: false, default: "" })
  protected resourceName!: string;

  protected confirmed = false;

  protected onClick(): void {
    if (!this.confirmed && this.mustConfirm) {
      this.showConfirmDialog();
      return;
    }

    this.$emit("click");

    this.confirmed = false;
  }

  protected showConfirmDialog(): void {
    this.$dialogsService
      .archiveConfirmDialog(this.resourceName !== "" ? this.resourceName : "")
      .then(() => {
        this.confirmed = true;
        this.onClick();
      })
      .catch(() => {
        this.confirmed = false;
      });
  }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ApiService from "@/services/ApiService";
import IMedia from "@/interfaces/IMedia";
import ExifReader from "exifreader";
import { HandlesUploads } from "@/components/media/mixin/HandlesUploads";

@Component({})
export class HandlesMultiUploads extends HandlesUploads {
  @Watch("value", { immediate: true, deep: true })
  protected onValueChanged(files: any): void {
    // --
  }

  @Watch("files", { immediate: true, deep: true })
  protected onFilesChanged(files: Array<any> | null): void {
    if (files) {
      this.loadMultiExifTags();
    }
  }

  protected file: File | null = null;

  protected files: Array<any> = [];

  protected multiExifTags: Array<any> = [];

  protected multiExifTagsLoading: Array<boolean> = [];

  protected validationErrors: Array<any> = [];

  protected isNewFiles: Array<boolean> = [];

  /*
    protected exifTags: any = null;
  protected isExifTagsLoading = false;
   */

  protected onFilesSelect(files: Array<any>): void {
    this.validationErrors = [];

    files.forEach((file: any, fileIdx: number) => {
      this.isNewFiles[fileIdx] = true;
    });

    this.$emit("input", files);
  }

  protected onUnselectFile(): void {
    this.file = null;
    this.isNewFile = false;
  }

  protected onRemoveFile(fileIdx: number): void {
    this.files.splice(fileIdx, 1);
  }

  protected upload(): void {
    this.isUploading = true;
    if (this.resource) {
      this.apiService
        .upload({
          resource: this.resource,
          data: {
            files: this.files,
          },
        })
        .then((response: any) => {
          this.$emit("uploaded", response.data);
          this.onUnselectFile();
        })
        .catch((error: any) => {
          if (error.response.status === 422) {
            if (error.response?.data?.errors) {
              this.validationErrors = error.response.data.errors;
            }
          }
        })
        .finally(() => {
          this.isUploading = false;
        });
    }
  }

  protected async loadMultiExifTags(): Promise<void> {
    if (this.files) {
      this.files.forEach((file: any) => {
        // --
      });
    }

    if (this.file) {
      this.isExifTagsLoading = true;
      //this.exifTags = await ExifReader.load(this.file);
      this.exifTags = await ExifReader.load(this.file);

      this.isExifTagsLoading = false;
    }
  }
}


import { Component, Prop, Watch } from "vue-property-decorator";
import InfoTable, { IInfoData } from "@/components/common/InfoTable.vue";
import {
  DEFAULT_BACKEND_DATETIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from "@/config/Formats";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import { mixins } from "vue-class-component";
import FormMixin from "@/mixins/Crud/FormMixin";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { eventBus } from "@/main";
import { EVENT } from "@/config/Consts";

@Component({
  components: { DefaultForm, InfoTable },
})
export default class PlantImageUploadItemForm extends mixins(
  FormMixin,
  PlantStoreMixin
) {
  @Prop({ required: true })
  protected file!: File;

  @Prop({ required: true })
  protected fileIdx!: number;

  @Prop({ required: false, default: null })
  protected exifTags!: any;

  @Prop({ required: false, default: false })
  protected isExifTagsLoading!: boolean;

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Watch("form.is_featured", { deep: true })
  protected onFormIsFeaturedChanged(isFeatured: boolean): void {
    if (isFeatured) {
      this.$emit("on-is_featured-set", isFeatured);
    }
  }

  protected form: any = {
    is_public: false,
    is_featured: false,
  };

  protected get fileData(): Array<IInfoData> {
    return [
      {
        text: this.file?.name ?? "?",
        label: String(
          this.$t(
            "components.modules.media.plant_image.plant_image_upload_item_form.info_table_labels.filename"
          )
        ),
      },
      {
        text:
          this.exifTags?.DateTime?.value?.length > 0
            ? this.$moment(
                this.exifTags.DateTime.value[0],
                "YYYY:MM:DD HH:mm:ss"
              ).format(DEFAULT_DATE_TIME_FORMAT)
            : "?",
        label: String(
          this.$t(
            "components.modules.media.plant_image.plant_image_upload_item_form.info_table_labels.created_at"
          )
        ),
      },
      {
        text: this.exifTags?.Model?.value[0] ?? "?",
        label: String(
          this.$t(
            "components.modules.media.plant_image.plant_image_upload_item_form.info_table_labels.camera_model"
          )
        ),
      },
    ];
  }

  public getFormValues(): any {
    return {
      filename: this.file.name,
      file: this.file,
      create_date:
        this.exifTags?.DateTime?.value?.length > 0
          ? this.$moment(
              this.exifTags.DateTime.value[0],
              "YYYY:MM:DD HH:mm:ss"
            ).format(DEFAULT_BACKEND_DATETIME_FORMAT)
          : null,
      is_public: this.form.is_public,
      is_featured: this.form.is_featured,
    };
  }

  public init(): void {
    console.debug("PlantImageUploadItem created (init)");
    this.registerBusListener();
  }

  protected registerBusListener(): void {
    eventBus.$on(EVENT.IMAGE_IS_FEATURED_CHANGE, (fileIdx: number) => {
      // Set off is_featured here, because another is set as featured.
      if (this.fileIdx !== fileIdx) {
        console.debug("eventBus is featured set to bla 2     ", fileIdx);
        this.form.is_featured = 0;
      }
    });
  }
}

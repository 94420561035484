
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { IPlant } from "@/interfaces/models/IPlant";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";
import EditButton from "@/components/buttons/EditButton.vue";
import DeleteButton from "@/components/buttons/table_actions/DeleteButton.vue";
import ArchiveButton from "@/components/buttons/table_actions/ArchiveButton.vue";

@Component({
  methods: {
    DEFAULT_DATE_FORMAT() {
      return DEFAULT_DATE_FORMAT;
    },
  },
  components: { ArchiveButton, DeleteButton, EditButton },
})
export default class PlantCard extends Vue {
  @Prop({ required: true })
  protected plant!: IPlant;

  @Prop({ required: false, default: false })
  protected isSelectModeActive!: boolean;

  @PropSync("selected", { required: false, default: false })
  protected isSelected!: boolean;

  protected isOpened = false;

  protected additionalProperties: Record<string, any> = {};

  @Watch("plant", { immediate: true, deep: true })
  protected onPlantChanged(plant: IPlant | null): void {
    if (plant) {
      this.setAdditionalProperties();
    }
  }

  protected onEditPlantClicked(): void {
    if (this.plant.archived_at) {
      this.$buefy.dialog.alert({
        message: String(
          this.$t("message.archived_resource_cant_be_edited", {
            resource_name: String(this.$tc("general.plants", 2)),
          })
        ),
        type: "is-warning",
        icon: "fa-solid fa-triangle-exclamation",
        hasIcon: true,
      });

      return;
    }

    this.$router.push({
      name: "PlantUpdate",
      params: { id: String(this.plant.id) },
    });
  }

  protected setAdditionalProperties() {
    this.additionalProperties = {
      pot_size: this.plant.pot_size,
      pot_capacity_liters: this.plant.pot_capacity_liters,
      pot_net_weight_grams: this.plant.pot_net_weight_grams,
      pot_type: this.plant.pot_type,
      watering_milliliters: this.plant.watering_milliliters,
      used_fertilizer: this.plant.used_fertilizer,
      soil_type: this.plant.soil_type,
    };
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GROWTH_STAGES } from "@/config/Consts";

@Component({
  computed: {
    GROWTH_STAGES() {
      return GROWTH_STAGES;
    },
  },
  components: {},
})
export default class GrowthStagesSteps extends Vue {
  @Prop({ required: true })
  protected growthStageStep!: number;

  protected mGrowStageStep = 0;

  @Watch("growthStageStep", { immediate: true })
  protected onGrowStageStepChanged(growthStageStep: number) {
    this.mGrowStageStep = this.growthStageStep;
  }
}

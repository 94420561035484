
import { Component } from "vue-property-decorator";
import NotAuthenticatedLayout from "@/layout/NotAuthenticatedLayout.vue";
import { mixins } from "vue-class-component";
import { AuthStoreMixin } from "@/mixins/Stores/Auth/AuthStoreMixin";

@Component({
  components: { NotAuthenticatedLayout },
})
export default class ResetPassword extends mixins(AuthStoreMixin) {
  protected email = "";

  protected password = "";

  protected passwordConfirmation = "";

  protected onSubmit(): void {
    this.resetPasswordAction({
      data: {
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        token: this.$route.query.token,
      },
      hideSuccessMessage: true,
    }).then((response: any) => {
      const dialog: any = this.$buefy.dialog.alert({
        message:
          "Das hat geklappt. Du wirst in wenigen Sekunden zum Login weitergeleitet",
      });

      setTimeout(() => {
        dialog.close();
        this.$router.replace({ name: "Login" });
      }, 10 * 1000);
    });
  }

  created(): void {
    this.email = String(this.$route?.query?.email ?? "");
  }
}

/**
 *
 * App Configuration
 */
export default class AppConfig {
  public static APP_NAME = "GrowSystemServer";
  public static APP_SHORT_NAME = "gss";
  public static ENTRYPOINT = `${process.env.VUE_APP_BACKEND_HOST}`;
  public static FULL_ENTRYPOINT =
    "https://" + `${process.env.VUE_APP_BACKEND_HOST}`;
  public static API_PREFIX = "/api";
  public static DATETIME_FORMAT_SQL = "YYYY-MM-DD HH:mm:ss";
  public static LOGGED_IN_HOME = "Home";
  public static NOT_LOGGED_IN_HOME = "Login";

  public static AUTH_STORE = "auth";
  public static USER_STORE = "user";
  public static USER_RESOURCE = "/user";

  public static DEVELOPER_MAIL = "maik@chronic-420.de";

  public static DEFAULT_TABLE_RESULTS_PER_PAGE = 20;
  public static DEFAULT_TABLE_PER_PAGE_OPTIONS: number[] = [
    1, 5, 10, 20, 50, 100,
  ];

  public static PUSHER: any = {
    key: `${process.env.VUE_APP_PUSHER_KEY}`,
    cluster: `${process.env.VUE_APP_PUSHER_CLUSTER}`,
  };

  public static DEFAULT_TOAST_DURATION = 3000; // ms
  public static DEFAULT_TOAST_POSITION:
    | "is-top-right"
    | "is-top"
    | "is-top-left"
    | "is-bottom-right"
    | "is-bottom"
    | "is-bottom-left"
    | undefined = "is-top"; // ms

  public static DEFAULT_AUTOCOMPLETE_DEBOUNCE_MILLIS = 500;
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { LabelSheet } from "@/config/Modules";
import { ILabelSheet } from "@/interfaces/models/ILabelSheet";
import RenderLabelHtml from "@/components/modules/plant/pdf/CreatePdfLabelSheet/RenderLabelHtml.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

interface SelectedCell {
  row: number;
  col: number;
}

@Component({
  components: { SubmitButton, RenderLabelHtml },
})
export default class CreatePdfLabelSheet extends Vue {
  @Prop({ required: true })
  protected plants!: Array<any>;

  protected isLabelSheetsLoading = false;
  protected selectedLabelSheet: ILabelSheet | null = null;
  protected labelSheets: Array<ILabelSheet> = [];
  protected selectedCells: SelectedCell[] = []; // Track selected cells

  protected renderCellHtml = false;

  protected isLoading = false;
  protected isSaving = false;

  protected fetchLabelSheets(): void {
    this.isLabelSheetsLoading = true;
    this.$apiService
      .get("/" + LabelSheet.resource)
      .then((response: any) => {
        if (response.data) {
          this.labelSheets = response.data;
        }
      })
      .finally(() => {
        this.isLabelSheetsLoading = false;
      });
  }

  protected init(): void {
    this.fetchLabelSheets();
  }

  created() {
    this.init();
  }

  get labelCellStyle() {
    if (!this.selectedLabelSheet) return {};

    const {
      rows_per_sheet,
      labels_per_row,
      horizontal_spacing_mm,
      vertical_spacing_mm,
    } = this.selectedLabelSheet;

    // Calculate available height and width based on sheet wrapper
    const sheetHeight = 700; // .sheet-wrapper height is 700px
    const sheetWidth = sheetHeight * 0.707; // A4 aspect ratio

    // Calculate total space used by spacings
    const totalVerticalSpacing = (rows_per_sheet - 1) * vertical_spacing_mm;
    const totalHorizontalSpacing = (labels_per_row - 1) * horizontal_spacing_mm;

    // Adjusted height and width for each cell
    const availableHeight =
      (sheetHeight - totalVerticalSpacing) / rows_per_sheet;
    const availableWidth =
      (sheetWidth - totalHorizontalSpacing) / labels_per_row;

    return {
      width: `${availableWidth}px`,
      height: `${availableHeight}px`,
      padding: `${vertical_spacing_mm / 2}px ${horizontal_spacing_mm / 2}px`,
      boxSizing: "border-box",
    };
  }

  protected toggleCellSelection(row: number, col: number) {
    const index = this.selectedCellIndex(row, col);

    if (index >= 0) {
      this.$delete(this.selectedCells, index); // Use Vue's $delete to maintain reactivity
    } else if (this.selectedCells.length < this.plants.length) {
      this.selectedCells.push({ row, col }); // Select new cell
    }
  }

  protected selectedCellIndex(row: number, col: number): number {
    return this.selectedCells.findIndex(
      (cell) => cell.row === row && cell.col === col
    );
  }

  protected isSelected(row: number, col: number): boolean {
    return this.selectedCells.some(
      (cell) => cell.row === row && cell.col === col
    );
  }

  protected createLabelSheetPdf(): void {
    this.isSaving = true;
    this.$apiService
      .download(
        "/plant/create-label-sheet-pdf",
        {
          data: {
            selected_labels: this.cellFlatIndexes,
            plants: this.plants,
            label_sheet_id: this.selectedLabelSheet?.id ?? null,
          },
        },
        true,
        null,
        { type: "application/pdf" }
      )
      .then((response: any) => {
        window.open(response, "_blank");
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  protected get cellFlatIndexes(): Array<number> {
    return this.selectedCells.map((selectedCell: SelectedCell) => {
      return selectedCell.row * 4 + selectedCell.col;
    });
  }
}

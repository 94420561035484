import Vue from "vue";
import Vuex from "vuex";
import Auth from "@/store/auth";
import createPersistedState from "vuex-persistedstate";
// @ts-ignore
import App from "@/store/app";
import Table from "@/store/table";
import User from "@/store/user";
import Plant from "@/store/plant";
import PlantDetail from "@/store/plantDetail";

Vue.use(Vuex);

const persistedStates = createPersistedState({
  paths: ["auth.token", "auth.accessToken", "auth.dataItem", "auth.language"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: new App(),
    auth: new Auth(),
    plant: new Plant(),
    plantDetail: new PlantDetail(),
    table: new Table(),
    user: new User(),
  },
  plugins: [persistedStates],
});


import { Component, Prop, Vue } from "vue-property-decorator";
import { IPlant } from "@/interfaces/models/IPlant";

@Component({
  components: {},
})
export default class RenderLabelHtml extends Vue {
  @Prop({ required: true })
  protected html!: string;

  @Prop({ required: true })
  protected plant!: IPlant;

  // This method replaces Laravel Blade-style placeholders with the corresponding values from the plant prop
  protected get renderedHtml(): string {
    let renderedHtml = this.html;

    // Regex to match Blade-style placeholders: {{ $variable }}
    const bladeRegex = /\{\{\s*\$(\w+)\s*\}\}/g;

    // Replace each match with the corresponding value from the plant prop
    renderedHtml = renderedHtml.replace(bladeRegex, (match, p1) => {
      // Get the value from the plant object using the matched key
      const value = (this.plant as any)[p1];
      // If the value exists, return it, otherwise return the original match
      let ret = "";

      if (value !== undefined) {
        ret = value;
      } else if (p1 === "plant_id") {
        ret = (this.plant as any)["id"];
      } else {
        ret = match;
      }

      return ret;
    });

    return renderedHtml;
  }
}


import { Component, Vue } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import PlantForm from "@/views/GSSApp/Plant/PlantForm.vue";
import { Plant } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: { PlantForm },
  mixins: [UpdateMixin],
})
export default class PlantUpdate extends mixins(UpdateMixin, PlantStoreMixin) {
  protected name = "PlantUpdate.vue";

  protected init(): void {
    this.findOnePlantItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return Plant.resource;
  }

  public get descriptionField(): string {
    return "plant";
  }

  updateItemAction(options: ICrudOptions): any {
    this.updatePlantItemAction(options);
  }
}

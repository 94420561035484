export default [
  {
    path: "login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login-view" */ "../../views/Auth/Login.vue"),
    meta: {
      title: "Login",
      //  middleware: [NotAuthenticatedMiddleware],
    },
  },
  {
    path: "login-with/:provider/callback",
    name: "LoginWithProviderCallback",
    component: () =>
      import(
        /* webpackChunkName: "login-with-provider-callback" */ "../../views/Auth/LoginWithCallback.vue"
      ),
    meta: {
      title: "Login",
      //  middleware: [NotAuthenticatedMiddleware],
    },
  },
  {
    path: "forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "auth--forgot-password" */ "../../views/Auth/ForgotPassword.vue"
      ),
    meta: {
      title: "Passwort vergessen",
      //middleware: [NotAuthenticatedMiddleware],
    },
  },
  {
    path: "reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "auth--reset-password" */ "../../views/Auth/ResetPassword.vue"
      ),
    meta: {
      title: "Passwort zurücksetzen",
      //middleware: [NotAuthenticatedMiddleware],
    },
  },
];

import { render, staticRenderFns } from "./DefaultForm.vue?vue&type=template&id=3d0740f0&scoped=true"
import script from "./DefaultForm.vue?vue&type=script&lang=ts"
export * from "./DefaultForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0740f0",
  null
  
)

export default component.exports
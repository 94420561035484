
import { Component, Prop } from "vue-property-decorator";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import PlantDetailForm from "@/views/GSSApp/Plant/PlantDetail/PlantDetailForm.vue";
import { Plant, PlantDetail } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { PlantDetailStoreMixin } from "@/mixins/Stores/PlantDetailStoreMixin";
import { IPlant } from "@/interfaces/models/IPlant";

@Component({
  components: { PlantDetailForm },
  mixins: [CreateMixin],
})
export default class PlantDetailCreate extends mixins(
  CreateMixin,
  PlantDetailStoreMixin
) {
  protected name = "PlantDetailCreate.vue";

  @Prop({ required: true })
  protected plant!: IPlant;

  public get resource(): string {
    return [
      Plant.resource,
      this.plant?.id ?? -1,
      PlantDetail.resource.replace("/", ""),
    ].join("/");
  }

  public get descriptionField(): string {
    return "id";
  }

  public redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "PlantDetailList",
      params: { id: String(this.plant.id) },
    });
  }

  public async createItemAction(options: ICrudOptions): Promise<any> {
    return this.createPlantDetailItemAction(options);
  }
}

import { Route } from "vue-router";
import AuthService from "@/services/AuthService";
//import { redirectUrl, hasMetaRoles } from "@/middlewares/helper";

const authService = new AuthService();

export default function auth(router: any) {
  const from: Route = router.from;
  const to: Route = router.to;
  const next = router.next;
  if (!authService.isAuthenticated()) {
    if (from.name !== "Login" && from.name !== to.name) {
      console.warn(
        "AuthMiddleware.auth NOT AUTHENTICATED REDIRECTING TO LOGIN"
      );
      return next({ name: "Login", query: { redirectUrl: to.fullPath } });
    }
  }

  return next();
}

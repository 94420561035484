
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import PlantDetailForm from "@/views/GSSApp/Plant/PlantDetail/PlantDetailForm.vue";
import { PlantDetail } from "@/config/Modules";
import { mixins } from "vue-class-component";

const PlantDetailModule = namespace("user");

@Component({
  components: { PlantDetailForm },
  mixins: [UpdateMixin],
})
export default class PlantDetailUpdate extends mixins(UpdateMixin) {
  protected name = "PlantDetailUpdate.vue";

  @PlantDetailModule.Action("findOne")
  protected loadItemAction: any;

  @PlantDetailModule.Action("update")
  public updateItemAction: any;

  @PlantDetailModule.Getter("getDataItem")
  protected getItem: any;

  @PlantDetailModule.Getter("getIsLoading")
  protected isLoading: any;

  protected init(): void {
    this.loadItemAction({
      id: this.$route.params.detail_id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return PlantDetail.resource;
  }

  public get descriptionField(): string {
    return "datetime_of_action";
  }
}


import { Component } from "vue-property-decorator";
import { PlantImage } from "@/config/Modules";
import PlantImageUpload from "@/components/media/PlantImage/PlantImageUpload.vue";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import GalleryItem from "@/components/media/PlantImage/GalleryItem.vue";

@Component({
  components: { GalleryItem, PlantImageUpload },
})
export default class PlantImageView extends mixins(PlantStoreMixin) {
  protected get imageResource(): string {
    return String(PlantImage.resource(Number(this.$route.params.id)));
  }

  // Vue Hooks
  created() {
    console.log("PlantImageView created fetch from: ", this.imageResource);
    this.findAllPlantImages({ resource: this.imageResource }, true);
  }
}


import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppConfig from "@/config/AppConfig";
import ChangeLocale from "@/components/change_locale/ChangeLocale.vue";
import { mixins } from "vue-class-component";
import { AppStoreMixin } from "@/mixins/Stores/AppStoreMixin";
import { getIsListLoading } from "@/store/crud/functions/getters";
import Str from "../../helper/Str";

const AuthModule = namespace(AppConfig.AUTH_STORE);

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  methods: { getIsListLoading },
  components: { ChangeLocale },
})
export default class Navbar extends mixins(AppStoreMixin) {
  protected name = "Navbar";

  @AuthModule.Action("logout")
  protected logoutAction: any;

  /*
  i.e.:

  protected registerEchoListeners(): void {
    this.$echo
      .channel("Customer")
      .listen(".CustomerUpdated", (e: any) => {
        this.loadCustomers();
      })
      .listen(".CustomerCreated", (e: any) => {
        this.loadCustomers();
      })
      .listen(".CustomerDeleted", (e: any) => {
        this.loadCustomers();
      });
  }
   */
  created() {
    //this.registerEchoListeners();
  }
}

import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  resetSave,
  del,
  update,
  upload,
  crudActions,
} from "@/store/crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

export function findAllImages(
  store: ActionContext<State, any>,
  options: ICrudOptions,
  force = true
) {
  if (
    force ||
    (options.force_reload ?? false) ||
    (!store.getters["getIsImagesLoading"] &&
      store.getters["getImages"]?.length <= 0)
  ) {
    store.commit("SET_IS_LOADING", true);
    store.commit("SET_IMAGES_LOADING", true);

    return crudActions
      .findAll(store, options)
      .then((response: any) => {
        console.debug("PlantImage", response, response.data);
        if (response.data) {
          store.commit("SET_IMAGE_LIST", response.data);
        }
        return response;
      })
      .catch((error: any) => {
        // Fehlerbehandlung, z.B. Anzeige einer Fehlermeldung
        console.error("Fehler beim Laden der Bilder:", error);
        // Weiterleiten des Fehlers, damit der Aufrufer ihn behandeln kann
        throw error; // Oder eine alternative Fehlerbehandlungsstrategie
      })
      .finally(() => {
        store.commit("SET_IS_LOADING", false);
        store.commit("SET_IMAGES_LOADING", false);
      });
  }

  return Promise.resolve(); // Rückgabe eines leeren Promise, wenn die Bedingung nicht erfüllt ist
}

/*

public async findAll(
    store: ActionContext<any, any>,
    options: ICrudOptions,
    force = false
): Promise<any> {
    if (
        force ||
(options.force_reload ?? false) ||
(!store.getters["getIsListLoading"] &&
    store.getters["getDataList"]?.length <= 0)
) {
    store.commit("SET_IS_LOADING", true);
    store.commit("SET_IS_LIST_LOADING", true);
    return new Promise((resolve, reject) => {
        return this.crudService
            .findAll(options)
            .then((response: any) => {
                resolve(response);
                this.resolveReadMultipleAction(store, response, options);
            })
            .catch((error: any) => {
                this.rejectReadAction(
                    store,
                    CrudActions.getErrorFromResponse(error),
                    options
                );
                reject(error);
            })
            .finally(() => {
                store.commit("SET_IS_LOADING", false);
                store.commit("SET_IS_LIST_LOADING", false);
            });
    });
}
}
 */

export default <ActionTree<State, any>>{
  create,
  findOne,
  update,
  findAll,
  resetSave,
  del,
  upload,
  findAllImages,
};

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"card p-3"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-field',{attrs:{"label":String(_vm.$t('general.email')),"type":_vm.getValidationErrors && _vm.getValidationErrors.email ? 'is-danger' : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.email
            ? _vm.getValidationErrors.email
            : ''}},[_c('b-input',{attrs:{"type":"email","placeholder":String(_vm.$t('placeholder.email')),"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-field',{attrs:{"label":String(_vm.$t('general.password')),"type":_vm.getValidationErrors && _vm.getValidationErrors.password
            ? 'is-danger'
            : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.password
            ? _vm.getValidationErrors.password
            : ''}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","placeholder":String(_vm.$t('placeholder.password')),"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('b-notification',{attrs:{"type":"is-danger","active":!!_vm.loginMessage,"closable":true}},[_vm._v(" "+_vm._s(_vm.loginMessage)+" ")]),_c('div',{staticClass:"mt-3 columns"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button is-primary is-pulled-left",attrs:{"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("capitalize")(String(_vm.$t("general.login")))))])],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-ghost","tag":"router-link","to":{ name: 'ForgotPassword' }}},[_vm._v(_vm._s(_vm.$t("general.password_forgotten")))])],1)])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
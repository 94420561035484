
import { Component, Prop, Vue } from "vue-property-decorator";
import IAnyListFilter from "@/components/any_list/interfaces/IAnyListFilter";

@Component({
  components: {},
})
export default class AnyList extends Vue {
  @Prop({ required: true })
  protected data!: Array<any>;

  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @Prop({ required: false, default: null })
  protected filters!: Array<IAnyListFilter>;

  @Prop({ required: false, default: "" })
  protected defaultSlotClasses!: Array<string>;
}


import { Component } from "vue-property-decorator";
import NotAuthenticatedLayout from "@/layout/NotAuthenticatedLayout.vue";
import { mixins } from "vue-class-component";
import { AuthStoreMixin } from "@/mixins/Stores/Auth/AuthStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: { NotAuthenticatedLayout },
})
export default class ForgotPassword extends mixins(AuthStoreMixin) {
  protected email = "";

  protected isMailSending = false;

  protected isMailSent = false;

  protected onSubmit(): void {
    this.isMailSending = true;

    this.sendPasswordResetLinkAction({
      data: { email: this.email },
      hideSuccessMessage: true,
    })
      .then((response: any) => {
        this.isMailSent = true;
      })
      .finally(() => {
        this.isMailSending = false;
      });
  }
}


import { Component, Vue } from "vue-property-decorator";
import "@/assets/sass/master.scss";
import BaseLayout from "@/layout/BaseLayout.vue";

@Component({
  components: { BaseLayout },
})
export default class GSSAppView extends Vue {
  protected name = "GSSAppView";
}

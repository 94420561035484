
import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import BaseLayout from "@/layout/BaseLayout.vue";
import AppConfig from "@/config/AppConfig";
import Dashboard from "@/components/dashboard/Dashboard.vue";

@Component({
  components: {
    Dashboard,
    BaseLayout,
    DefaultLayout,
  },
})
export default class Home extends Vue {}

import { Component, Vue } from "vue-property-decorator";
import ExifReader from "exifreader";

@Component({})
export class HandlesImages extends Vue {
  protected file?: File;

  protected isExifTagsLoading = false;

  protected exifTags: any = null;

  protected async loadExifTags(): Promise<void> {
    if (this.file) {
      this.isExifTagsLoading = true;
      //this.exifTags = await ExifReader.load(this.file);
      this.exifTags = await ExifReader.load(this.file);

      this.isExifTagsLoading = false;
    }
  }
}

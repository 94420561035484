import { Component, Vue } from "vue-property-decorator";
import { IPlantDetail } from "@/interfaces/models/IPlantDetail";

@Component({
  components: {},
})
export default class PlantDetailMixin extends Vue {
  protected recentEventActionData: IPlantDetail | null = null;
  protected isRecentEventActionDataLoading = false;

  protected fetchRecentEventActionData(
    plantId: number,
    event: string,
    actionCallback: any = null
  ) {
    this.isRecentEventActionDataLoading = true;
    const url = ["", "plant", plantId, "recent-details", event].join("/");

    this.$apiService
      .get(url)
      .then((response: any) => {
        if (response.data) {
          this.recentEventActionData = response.data;
        }

        actionCallback(response.data);
      })
      .finally(() => {
        this.isRecentEventActionDataLoading = false;
      });
  }

  protected getRecentEventActionData(catalogId: number, event: string): any {
    if (!this.recentEventActionData && !this.isRecentEventActionDataLoading) {
      this.fetchRecentEventActionData(catalogId, event);

      return null;
    }
    // TODO change any to IPlantDetail
    return this.recentEventActionData;
  }

  protected getPlantId(): number | null {
    return this.$route?.params?.id ? Number(this.$route.params.id) : null;
  }

  protected getPotShapeBySizeString(size: string): "round" | "square" {
    if (size.split("x").length > 2) {
      return "square";
    }

    return "round";
  }

  protected getWeekOfFlowering(day: number): number {
    let week = 1;
    if (day > 7) {
      week = Math.floor(day / 7) + 1;
    }
    return week;
  }
}

import store from "@/store";
import IUser from "@/mixins/interface/IUser";

export default class AuthService {
  public isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  public getToken(): any {
    return store.getters["auth/getToken"];
  }

  public getAccessToken(): string {
    return store.getters["auth/getAccessToken"];
  }

  public get currentUser(): IUser | null {
    const item: any = store.getters["auth/getDataItem"];
    if (item === {}) return null;
    return item;
  }

  public get isAdmin(): boolean {
    return this.currentUser?.is_admin ?? false;
  }

  public setUnauthenticated() {
    store.commit("auth/CLEAR_AUTHENTICATION_DATA");
  }
}

import { ActionContext } from "vuex";
// @ts-ignore
import CrudService from "@/services/CrudService";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import i18n from "@/locales";
import mStore from "@/store";
import router from "@/router";
import MessageService from "@/services/MessageService";

const messageService: MessageService = new MessageService();

/**
 * Basic CRUD actions
 */
export default class CrudActions {
  protected crudService: CrudService = new CrudService();

  /**
   * @param store
   * @param options
   */
  public async findOne(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    store.commit("SET_IS_LOADING", !options.disabledLoading);
    const request: Promise<any> = this.crudService.findOne(options);

    request
      .then((response: any) => {
        this.resolveSingleAction(store, response, options);
      })
      .catch((error) => {
        this.rejectReadAction(
          store,
          String(i18n.t(CrudActions.getErrorFromResponse(error))),
          options
        );
      });

    return request;
  }

  /**
   * @param store
   * @param options
   * @param force
   */
  public async findAll(
    store: ActionContext<any, any>,
    options: ICrudOptions,
    force = true
  ): Promise<any> {
    if (
      force ||
      (options.force_reload ?? false) ||
      (!store.getters["getIsListLoading"] &&
        store.getters["getDataList"]?.length <= 0)
    ) {
      store.commit("SET_IS_LOADING", true);
      store.commit("SET_IS_LIST_LOADING", true);
      return new Promise((resolve, reject) => {
        return this.crudService
          .findAll(options)
          .then((response: any) => {
            resolve(response);
            this.resolveReadMultipleAction(store, response, options);
          })
          .catch((error: any) => {
            this.rejectReadAction(
              store,
              CrudActions.getErrorFromResponse(error),
              options
            );
            reject(error);
          })
          .finally(() => {
            store.commit("SET_IS_LOADING", false);
            store.commit("SET_IS_LIST_LOADING", false);
          });
      });
    }
  }

  /**
   * @param store
   * @param options
   */
  public async findAllSubresource(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    const resource = {};
    // @ts-ignore
    resource[options.resource] = true;

    store.commit("SET_IS_SUBRESOURCE_LOADING", resource);
    return this.crudService
      .findAllSubresource(options)
      .then((response: any) => {
        console.log(
          "hello from subresoure loading bla response",
          response.data
        );
        if (response.data) {
          const resourceData = {};
          // @ts-ignore
          resourceData[options.resource] = response.data;
          store.commit("SET_SUBRESOURCE", resourceData);
        }
      })
      .catch((error: any) => {})
      .finally(() => {
        // @ts-ignore
        resource[options.resource] = false;
        store.commit("SET_IS_SUBRESOURCE_LOADING", resource);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async create(store: ActionContext<any, any>, options: ICrudOptions) {
    store.commit("SET_IS_LOADING", !options.disabledLoading);
    store.commit("SET_IS_SAVING", true);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ITEM", null);
    store.commit("SET_VALIDATION_ERRORS", null);
    store.commit("SET_ERROR", null);

    return new Promise((resolve, reject) => {
      this.crudService
        .create(options)
        .then((response: any) => {
          resolve(response);
          const itemLabel: string = CrudActions.findItemLabel(
            response,
            options
          );

          this.resolveWriteAction(
            store,
            response,
            String(
              i18n.t("message.save_success", {
                item: itemLabel,
              })
            ),
            response.data,
            options
          );
          store.commit("SET_DATA_ITEM", response.data);
          store.commit("SET_SUCCESS", true);
        })
        .catch((error: any) => {
          this.rejectWriteAction(store, error, options);
          store.commit("SET_DATA_ITEM", null);
          store.commit("SET_SUCCESS", false);
          reject(error);
        })
        .finally(() => {
          store.commit("SET_IS_LOADING", false);
          store.commit("SET_IS_SAVING", false);
        });
    });
  }

  /**
   * @param store
   * @param options
   */
  public async update(store: ActionContext<any, any>, options: ICrudOptions) {
    store.commit("SET_SUCCESS", false);
    store.commit("SET_IS_SAVING", true);
    store.commit("SET_VALIDATION_ERRORS", null);
    store.commit("SET_ERROR", null);

    return new Promise((resolve, reject) => {
      return this.crudService
        .update(options)
        .then((response: any) => {
          const itemLabel: string = CrudActions.findItemLabel(
            response,
            options
          );
          this.resolveWriteAction(
            store,
            response,
            String(
              i18n.t("message.save_success", {
                item: itemLabel,
              })
            ),
            response.data,
            options
          );

          if (response.data) {
            store.commit(
              "SET_DATA_ITEM",
              response.data && response.data.data
                ? response.data.data
                : response.data
            );
          }
          store.commit("SET_SUCCESS", true);
          resolve(response);
        })
        .catch((error: any) => {
          this.rejectWriteAction(store, error, options);
          store.commit("SET_SUCCESS", false);
          //  store.commit("SET_DATA_ITEM", null);
          reject(error);
        })
        .finally(() => {
          store.commit("SET_IS_SAVING", false);
        });
    });
  }

  /**
   * @param store
   * @param options
   */
  public async delete(
    store: ActionContext<any, any>,
    options: ICrudOptions
  ): Promise<any> {
    store.commit("SET_IS_LOADING", true);
    store.commit("SET_DELETED_SUCCESS", false);
    store.commit("SET_IS_REMOVING", true);
    return this.crudService
      .delete(options)
      .then((response: any) => {
        store.commit("SET_DELETED_SUCCESS", true);
        this.resolveWriteAction(
          store,
          response,
          String(i18n.t("message.delete_success")),
          null,
          options
        );
        store.commit("SET_DELETED_SUCCESS", true);
      })
      .catch((error: any) => {
        this.rejectWriteAction(store, error, options);
        store.commit("SET_DELETED_SUCCESS", false);
      })
      .finally(() => {
        store.commit("SET_IS_REMOVING", false);
      });
  }

  public uploadFile(store: ActionContext<any, any>, params: ICrudOptions) {
    return new Promise((resolve, reject) => {
      this.crudService
        .uploadFile(params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          switch (error.response.status) {
            case 422:
              store.commit("SET_ERROR", error.response.data.message);
              store.commit("SET_VALIDATION_ERRORS", error.response.data.errors);
              break;
          }
          reject(error);
        })
        .finally(() => {});
    });
  }

  public resolveWriteAction(
    store: ActionContext<any, any>,
    response: any,
    successMessage: string,
    item: any,
    options: ICrudOptions
  ): void {
    store.commit("SET_ERROR", null);
    store.commit("SET_IS_LOADING", false);
    if (!options.hideSuccessMessage) {
      messageService.success(successMessage);
    }
  }

  /**
   * Actions to perform on write action promise reject
   * @param store
   * @param error
   * @param options
   */
  public rejectWriteAction(
    store: ActionContext<any, any>,
    error: any,
    options: ICrudOptions
  ): void {
    const errMsg = CrudActions.getErrorFromResponse(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 422:
          store.commit("SET_VALIDATION_ERRORS", error.response.data.errors);
          break;
        default:
        // this.dispatchErrorMessage(store, errMsg).then(() => {});
      }
    } else {
      // this.dispatchErrorMessage(store, errMsg).then(() => {});
    }

    store.commit("SET_ERROR", error);
    store.commit("SET_IS_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   * @param options
   */
  public resolveReadMultipleAction(
    store: ActionContext<any, any>,
    response: any,
    options: ICrudOptions
  ): void {
    if (response.data) {
      if (response.data.data) {
        store.commit("SET_DATA_LIST", response.data.data);
      } else {
        store.commit("SET_DATA_LIST", response.data);
      }

      store.commit("SET_TOTAL", response.data.total);
      store.commit("SET_ERROR", null);
      this.storeListResponse(response);
    }
  }

  /**
   * Actions to perform on read single action promise resolve
   * @param store
   * @param response
   * @param options
   */
  protected resolveSingleAction(
    store: ActionContext<any, any>,
    response: any,
    options: ICrudOptions
  ): void {
    store.commit(
      "SET_DATA_ITEM",
      response.data && response.data.data ? response.data.data : response.data
    );
    store.commit("SET_ERROR", null);
    store.commit("SET_IS_LOADING", false);
  }

  /**
   * Actions to perform on rad action promise reject
   * @param store
   * @param error
   * @param options
   */
  public rejectReadAction(
    store: ActionContext<any, any>,
    error: any,
    options: ICrudOptions
  ) {
    console.error("rejectReadAction Error", error);
    if (error === "Unauthenticated.") {
      mStore.commit("auth/CLEAR_AUTHENTICATION_DATA");
    }

    store.commit("SET_ERROR", error);
    store.commit("SET_IS_LOADING", false);
  }

  private static getErrorFromResponse(error: any): any {
    let msg = "Unknown Error occurred";
    if (error.exception) {
      msg = error.exception;
    } else if (error && error.response) {
      if (error.response.data.message) {
        msg = error.response.data.message;
      } else if (error.response.message) {
        msg = error.response.message;
      } else if (error.response.data.exception) {
        msg = error.response.data.exception;
      }
    }
    return msg;
  }

  /**
   * Store list response data for the new table store
   *
   * @param response
   */
  public storeListResponse(response: any): void {
    if (response.data.data && response.data.meta) {
      // Store table data to table store
      mStore.commit("table/SET_PAGINATION_LINKS", response.data.links);
      mStore.commit("table/SET_PAGINATION_META", response.data.meta);
      mStore.commit("table/SET_TOTAL_ITEMS", response.data.meta.total);
      mStore.commit("table/SET_CURRENT_PAGE", response.data.meta.current_page);
      mStore.commit("table/SET_ITEMS_PER_PAGE", response.data.meta.per_page);
    } else if (response.data.links) {
      // Store table data to table store
      mStore.commit("table/SET_PAGINATION_LINKS", response.data.links);
      //   mStore.commit('table/SET_PAGINATION_META', response.data.meta);
      mStore.commit("table/SET_TOTAL_ITEMS", response.data.total);
      mStore.commit("table/SET_CURRENT_PAGE", response.data.current_page);
      mStore.commit("table/SET_ITEMS_PER_PAGE", response.data.per_page);
    }
  }

  private static findItemLabel(response: any, options: ICrudOptions) {
    const data: any =
      response.data && response.data.data ? response.data.data : response.data;
    const resource: string =
      options.resource.substr(0, 1) === "/"
        ? options.resource.substr(1, options.resource.length - 1)
        : options.resource;
    const itemLabel: string =
      options.descriptionField && data[options.descriptionField]
        ? data[options.descriptionField]
        : resource;

    return itemLabel;
  }
}

import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  create,
  findOne,
  findAll,
  resetSave,
  del,
  upload,
  crudActions,
} from "@/store/crud/functions/actions";
import { CrudState } from "@/store/crud/State";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ApiService from "@/services/ApiService";
import router from "@/router";
import AppConfig from "@/config/AppConfig";
import axios, { AxiosRequestConfig } from "axios";
import AuthService from "@/services/AuthService";

export async function login(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_IS_LOADING", true);
  store.commit("SET_VALIDATION_ERRORS", null);
  //store.commit("SET_MESSAGE", "");

  options.resource = "/login";
  console.log("Store auth actions", options);
  return new ApiService()
    .post(options.resource, options.data)
    .then((response: any) => {
      if (response.data) {
        store.commit("SET_ACCESS_TOKEN", response.data.accessToken);
        store.commit("SET_TOKEN", response.data.token);

        console.log("Store auth actions 2", options, response.data);

        store
          .dispatch("refreshUserData", response.data.accessToken)
          .then(() => {
            router.replace({ name: AppConfig.LOGGED_IN_HOME });
          });
      }
    })
    .catch((error: any) => {
      if (error.response.data.error && error.response.data.message) {
        console.log("Store auth actions 3", options, error.response);
        //store.commit("SET_MESSAGE", error.response.data.message);
        store.commit("SET_LOGIN_MESSAGE", error.response.data.message);
      }
    })
    .finally(() => {
      store.commit("SET_IS_LOADING", false);
    });
}

export async function loginWith(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_IS_LOADING", true);
  store.commit("SET_VALIDATION_ERRORS", null);

  options.resource = "/login-with/" + options.data.provider + "/callback";

  console.log("Store auth actions login with", options);

  return new Promise((resolve: any, reject: any) => {
    return new ApiService()
      .get(options.resource, options.data.query)
      .then((response: any) => {
        if (response.data) {
          if (response.data) {
            store.commit("SET_ACCESS_TOKEN", response.data.accessToken);
            store.commit("SET_TOKEN", response.data.token);

            store
              .dispatch("refreshUserData", response.data.accessToken)
              .then(() => {
                router.replace({ name: AppConfig.LOGGED_IN_HOME });
              });
          }
        }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
        if (error.response.data.message) {
          //store.commit("SET_MESSAGE", error.response.data.message);
        }
      })
      .finally(() => {
        store.commit("SET_IS_LOADING", false);
      });
  });
}

export async function logout(
  store: ActionContext<CrudState, any>
): Promise<any> {
  store.commit("SET_IS_LOADING", true);
  const token: any = store.getters.getToken;

  return new ApiService()
    .delete("/logout/" + token.id)
    .then((response: any) => {})
    .catch((error: any) => {
      console.error("Logout error", error.response);
    })
    .finally(() => {
      store.commit("CLEAR_AUTHENTICATION_DATA");
      store.commit("SET_IS_LOADING", false);
      router.push({ name: AppConfig.NOT_LOGGED_IN_HOME });
    });
}

export async function uploadProfileImage(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  store.commit("SET_IS_SAVING", true);
  return new Promise((resolve, reject) => {
    return upload(store, options)
      .then((response: any) => {
        if (response.data && response.data.data) {
          store.commit("SET_IMAGE_DATA", response.data.data);
        }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      })
      .finally(() => {
        store.commit("SET_IS_SAVING", false);
      });
  });
}

export async function refreshUserData(
  store: ActionContext<CrudState, any>,
  token = ""
) {
  return new Promise((resolve, reject) => {
    if (token === "") {
      token = new AuthService().getAccessToken();
    }

    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config?.headers) {
        config.headers["Authorization"] = ["Bearer", token].join(" ");
      }

      return config;
    });

    const as = new ApiService();
    return as
      .get("/user")
      .then((response: any) => {
        if (response.data) {
          store.commit("SET_DATA_ITEM", response.data);
        }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function update(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
): Promise<any> {
  return crudActions.update(store, options);
}

export async function sendPasswordResetLink(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
) {
  options.resource = "/forgot-password";

  return crudActions.create(store, options);
}

export async function resetPassword(
  store: ActionContext<CrudState, any>,
  options: ICrudOptions
) {
  options.resource = "/reset-password";

  return crudActions.create(store, options);
}

export default <ActionTree<State, any>>{
  create,
  findOne,
  login,
  loginWith,
  update,
  findAll,
  resetSave,
  del,
  logout,
  upload,
  uploadProfileImage,
  refreshUserData,
  sendPasswordResetLink,
  resetPassword,
};


import { Component, Prop, Watch } from "vue-property-decorator";
import PlantImageUploadItemForm from "@/components/media/PlantImage/PlantImageUpload/ImageGallery/PlantImageUploadItemForm.vue";
import { mixins } from "vue-class-component";
import { HandlesImages } from "@/components/media/mixin/HandlesImages";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { Plant } from "@/config/Modules";

@Component({
  components: { PlantImageUploadItemForm },
})
export default class PlantImageUploadItem extends mixins(
  HandlesImages,
  PlantStoreMixin
) {
  @Prop({ required: true })
  protected plantId!: number;

  @Prop({ required: true })
  protected file!: File;

  @Prop({ required: true })
  protected fileIdx!: number;

  @Watch("file", { immediate: true, deep: true })
  protected onFileChanged(file: File | null): void {
    if (file) {
      this.loadExifTags();
    }
  }

  protected maxFilenameLength = 24;

  protected get fileUrl(): string {
    if (this.file) {
      return window.URL.createObjectURL(new Blob([this.file]));
    }

    return "https://picsum.photos/400/300";
  }

  protected onPlantImageUploadItemFormSubmit(data: any): void {
    this.uploadPlantAction({
      resource: [Plant.resource, this.plantId, "image", "upload"].join("/"),
      data: {
        file: this.file,
        ...data,
      },
    }).then((response: any) => {
      this.$emit("uploaded", response?.data);
    });
  }
}


import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListMixin from "@/mixins/Crud/ListMixin";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { Plant } from "@/config/Modules";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import AnyList from "@/components/any_list/AnyList.vue";
import PlantCard from "@/components/modules/plant/PlantCard.vue";
import { bus } from "@/init/_bus";
import { ON_ARCHIVE_SUCCESS, ON_REMOVE_SUCCESS } from "@/config/Events";
import plant from "@/router/Plant";
import { IPlant } from "@/interfaces/models/IPlant";
import { TPlantMap } from "@/interfaces/types/TPlantMap";
import CreatePdfLabelSheet from "@/components/modules/plant/pdf/CreatePdfLabelSheet.vue";
import PlantDetailForm from "@/views/GSSApp/Plant/PlantDetail/PlantDetailForm.vue";
import { PlantDetailStoreMixin } from "@/mixins/Stores/PlantDetailStoreMixin";
import MiniPlantCard from "@/components/modules/plant/MiniPlantCard.vue";

@Component({
  methods: {
    plant() {
      return plant;
    },
  },
  components: {
    MiniPlantCard,
    PlantDetailForm,
    CreatePdfLabelSheet,
    PlantCard,
    AnyList,
  },
  mixins: [ListMixin, PlantStoreMixin],
})
export default class PlantMyPlants extends mixins(
  ListMixin,
  PlantStoreMixin,
  PlantDetailStoreMixin
) {
  public name = "PlantList.vue";

  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "plant" })
  public descriptionField!: string;

  @Watch("isModeArchiveActive", { immediate: false })
  protected onModeArchiveActiveChanged(active: any): void {
    this.onReloadTable(true);
  }

  protected isSelectModeActive = false;

  protected selectedPlantCards: any = {};

  protected modeArchiveActive = false;

  protected get selectedPlants(): Array<IPlant> {
    return this.getPlantList.filter((plant: IPlant, rowIdx: number) => {
      return this.selectedPlantCards[rowIdx];
    });
  }

  protected isPdfLabelSheetModalVisible = false;

  protected isMassPlantActionModalVisible = false;

  public get resource(): string {
    return Plant.resource + "/my";
  }

  protected get selectedPlantsCount(): number {
    return Object.keys(this.selectedPlants).length;
  }

  protected get isModeArchiveActive(): boolean {
    //return this.$route?.query?.archived == "1";
    return this.modeArchiveActive;
  }

  protected set isModeArchiveActive(active: boolean) {
    this.modeArchiveActive = active;
  }

  protected loadList(options: ICrudOptions): void {
    if (this.isModeArchiveActive) {
      //options.extraQueryParams["archived"] = true;
      let params: any = { ...(options.params ?? {}) };
      params = { ...params, archived: this.isModeArchiveActive ? "1" : "0" };
      options.params = params;
    }

    this.findAllPlantItemsAction(options);
  }

  protected onSelectModeActiveClick(): void {
    this.isSelectModeActive = !this.isSelectModeActive;
    if (!this.isSelectModeActive) {
      this.selectedPlantCards = {};
    }
  }

  protected onMassPlantActionClick(): void {
    this.isMassPlantActionModalVisible = true;
  }

  protected onSubmitMassPlantAction(formValues: IPlant): void {
    const data = {
      plant_ids: this.selectedPlants.map((plant: IPlant) => plant.id),
      ...formValues,
    };

    const options: ICrudOptions = {
      resource: this.resource + "/plant-detail/mass-create",
      data,
    };

    this.createPlantDetailItemAction(options).then((response: any) => {
      this.deselectAllPlants();
      this.isMassPlantActionModalVisible = false;
    });
  }

  protected onPrintSheetClick(): void {
    this.isPdfLabelSheetModalVisible = true;
  }

  protected selectAllPlants(select = true): void {
    if (select) {
      if (this.getPlantList && Array.isArray(this.getPlantList)) {
        this.getPlantList.forEach((plant: IPlant, plantIdx: number) => {
          this.selectedPlantCards[plantIdx] = true;
        });
      }

      return;
    }

    this.selectedPlantCards = {};
  }

  protected get batchIDs(): Array<number> {
    let batchIDs: Array<number> = [];

    this.getPlantList.forEach((plant: IPlant) => {
      if (plant.batch_id && !batchIDs.includes(plant.batch_id)) {
        batchIDs.push(plant.batch_id);
      }
    });

    return batchIDs;
  }

  protected getPlantsListByBatch(batchID: number): Array<IPlant> {
    if (this.getPlantList && this.getPlantList.length > 0) {
      return this.getPlantList.filter((plant: IPlant) => {
        return plant.batch_id === batchID;
      });
    }

    return [];
  }

  protected deselectAllPlants(): void {
    this.selectAllPlants(false);
  }

  protected initListeners(): void {
    bus.$on(ON_ARCHIVE_SUCCESS, (options: any) => {
      this.onReloadTable(true);
    });

    bus.$on(ON_REMOVE_SUCCESS, (options: any) => {
      this.onReloadTable(true);
    });
  }

  public init() {
    this.initListeners();
  }
}

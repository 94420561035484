
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { Plant, PlantDetail } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { PlantDetailStoreMixin } from "@/mixins/Stores/PlantDetailStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IPlantDetail } from "@/interfaces/models/IPlantDetail";
import AnyList from "@/components/any_list/AnyList.vue";
import { DEFAULT_DATE_TIME_FORMAT } from "@/config/Formats";
import DeleteButton from "@/components/buttons/table_actions/DeleteButton.vue";
import EditButton from "@/components/buttons/EditButton.vue";
import GrowthStagesSteps from "@/components/modules/plant/GrowthStagesSteps.vue";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";

@Component({
  computed: {
    DEFAULT_DATE_TIME_FORMAT() {
      return DEFAULT_DATE_TIME_FORMAT;
    },
  },
  components: {
    GrowthStagesSteps,
    EditButton,
    DeleteButton,
    AnyList,
    DefaultTableLayout,
  },
  mixins: [ListMixin],
})
export default class PlantDetailList extends mixins(
  ListMixin,
  PlantStoreMixin,
  PlantDetailStoreMixin
) {
  public name = "PlantDetailList.vue";

  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "plant" })
  public descriptionField!: string;

  protected hoveredEntry = -1;

  public get resource(): string {
    return [
      Plant.resource,
      this.$route.params.id,
      PlantDetail.resource.replace("/", ""),
    ].join("/");
  }

  protected loadList(options: ICrudOptions): void {
    options.params["perPage"] = 10000;
    this.findAllPlantDetailItemsAction(options);
  }

  protected onRowClick(row: IPlantDetail): void {
    if (row.id) {
      this.$router.push({
        name: "PlantDetailShow",
        params: { id: String(row.id) },
      });
    }
  }

  protected onDeleteClick(id: number): void {
    this.delPlantDetailItemAction({
      resource: PlantDetail.resource,
      id: id,
    });
  }

  public init() {}
}


import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import { IPlant } from "@/interfaces/models/IPlant";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { DropdownStrains, Plant } from "@/config/Modules";
import debounce from "lodash/debounce";
import AppConfig from "@/config/AppConfig";
import { STRAIN } from "@/config/FaIcon";

@Component({
  computed: {
    STRAIN_ICON() {
      return STRAIN;
    },
  },
  components: { SaveButton, DefaultForm },
})
export default class PlantForm extends mixins(FormMixin, PlantStoreMixin) {
  protected name = "PlantForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IPlant;

  protected id: number | null = null;
  protected plant = "";
  protected strain = "";
  protected strainId: number | null = null;
  protected comment = "";
  protected planted_as = "";
  protected planted_at: string | null = null;
  protected parentPlantId: number | null = null;
  protected deleted_at: Date | string | null = null;
  protected archived_at: Date | string | null = null;

  protected plants: Array<IPlant> = [];
  protected strains: Array<any> = [];

  protected isPlantsLoading = false;
  protected isStrainsLoading = false;

  protected isParentPlantDropdownOpened = false;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IPlant) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): IPlant {
    return {
      id: this.id ?? undefined,
      plant: this.plant,
      strain: this.strain,
      strain_id: this.strainId,
      comment: this.comment,
      planted_as: this.planted_as,
      planted_at: this.planted_at,
      parent_plant_id: this.parentPlantId,
      deleted_at: this.deleted_at ?? null,
      archived_at: this.archived_at ?? null,
    };
  }

  protected setFormValues(data: IPlant): void {
    if (data) {
      this.id = data.id ?? null;
      this.plant = data.plant;
      this.strain = data.strain;
      this.strainId = data.strain_id;
      this.comment = data.comment ?? "";
      this.planted_as = data.planted_as;
      this.planted_at = data.planted_at ?? null;
      this.parentPlantId = data.parent_plant_id ?? null;
      this.deleted_at = data.deleted_at ?? null;
      this.archived_at = data.archived_at ?? null;
    }
  }

  protected get parentPlantDropdownTrigger(): string {
    if (this.parentPlantId) {
      const plant: IPlant | undefined = this.plants.find(
        (plant: IPlant) => plant.id === this.parentPlantId
      );

      if (plant) {
        return plant.plant + " (" + plant.strain + ")";
      }

      return String(this.$i18n.t("modules.plant.parent_plant_no_records"));
    }

    return String(
      this.$i18n.t("modules.plant.placeholder.parent_plant_dropdown")
    );
  }

  protected onSubmit() {
    this.$emit("submit", this.getFormValues());
  }

  protected onAbort() {
    this.$emit("abort", this.getFormValues());
  }

  protected loadPlants(withArchived = false): void {
    this.isPlantsLoading = true;
    this.$crudService
      .findAll({
        resource: [Plant.resource, "my"].join("/"),
        params: { with_archived: 1 },
      })
      .then((response: any) => {
        const plants: Array<IPlant> = response.data?.data ?? response.data;
        this.plants = plants.filter((plant: IPlant) => plant.id !== this.id);
      })
      .finally(() => {
        this.isPlantsLoading = false;
      });
  }

  protected onStrainsTyping = debounce((name: string) => {
    this.loadStrains(name);
  }, AppConfig.DEFAULT_AUTOCOMPLETE_DEBOUNCE_MILLIS);

  protected loadStrains(name = ""): void {
    this.isStrainsLoading = true;

    this.$crudService
      .findAll({
        resource: DropdownStrains.resource,
        params: { name: name },
      })
      .then((response: any) => {
        this.strains = response.data?.data ?? response.data;
      })
      .finally(() => {
        this.isStrainsLoading = false;
      });
  }

  protected onChangeParentPlantId(parentPlantId: number | null): void {
    if (parentPlantId && this.plants) {
      const plant: IPlant | undefined = this.plants.find(
        (plant: IPlant) => plant.id === parentPlantId
      );
      if (plant) {
        this.strain = plant.strain;
        if (plant.strain_id) {
          this.strainId = plant.strain_id;
        } else {
          this.strainId =
            this.strains.find((strain: any) => strain.name === plant.strain)
              ?.id ?? null;
        }
      }
    }
  }

  protected onSelectStrain(strain: any) {
    this.strainId = strain?.id ?? null;
    this.strain = strain?.name ?? "";
  }

  public init(): void {
    this.loadPlants(true);
    this.loadStrains();
  }
}

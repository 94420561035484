
import { Component, Vue } from "vue-property-decorator";
import BaseLayout from "@/layout/BaseLayout.vue";

@Component({
  components: { BaseLayout },
})
export default class NotAuthenticatedLayout extends Vue {
  protected name = "NotAuthenticatedLayout";
}

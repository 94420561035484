import { ExifTags } from "exifreader";
import AppConfig from "@/config/AppConfig";

export class ImageService {
  public getFullUrl(url: string): string {
    return AppConfig.FULL_ENTRYPOINT + url;
  }

  public getDateTime(exifData: any): Date | null {
    if (exifData["DateTime"] && exifData["DateTime"]["value"]) {
      const splitDate = exifData["DateTime"]["value"][0].split(" ");

      const formattedDateString = splitDate[0].replace(":", "-");

      return new Date(formattedDateString);
    }

    return null;
  }
}


import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import { IPlantDetail } from "@/interfaces/models/IPlantDetail";
import { mixins } from "vue-class-component";
import { PlantDetailStoreMixin } from "@/mixins/Stores/PlantDetailStoreMixin";
import { DEFAULT_BACKEND_DATETIME_FORMAT } from "@/config/Formats";
import { GROWTH_STAGES, PLANT_DETAIL_EVENTS } from "@/config/Consts";
import PlantDetailMixin from "@/mixins/Modules/Plant/PlantDetailMixin";

@Component({
  components: { SaveButton, DefaultForm },
})
export default class PlantDetailsForm extends mixins(
  FormMixin,
  PlantDetailStoreMixin,
  PlantDetailMixin
) {
  public name = "PlantDetailsForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IPlantDetail;

  protected form: IPlantDetail = {
    id: -1,
    pot_size: "",
    pot_capacity_liters: null,
    pot_net_weight_grams: null,
    watering_milliliters: null,
    with_fertilizer: false,
    used_fertilizer: "",
    plant_height_cm: null,
    datetime_of_action: new Date(),
    soil_type: "",
    growth_stage: "",
    event: "",
    event_info: "",
    plant_id: null,
    harvested_at: null, // New property
    harvested_wet_value_g: null, // New property
    harvested_dry_brutto_g: null, // New property
    finished_dry_at: null, // New property
    harvested_dry_netto_g: null, // New property
  };

  protected potShape: "round" | "square" = "square";

  private potHeight = 0;

  private potDiameter = 0;

  private potWidth = 0;

  private potLength = 0;

  @Watch("data", { immediate: true, deep: true })
  private onDataChanged(data: IPlantDetail) {
    if (data) {
      this.setFormValues(data);
    }
  }

  @Watch("form.event", { deep: true })
  private onFormEventChange(event: string): void {
    console.debug("onFormEventChange", event);
    switch (event) {
      case "harvesting":
        this.form.growth_stage = "harvest";
        break;
    }
  }

  public getFormValues(): IPlantDetail {
    return {
      ...this.form,
      datetime_of_action: this.$moment(this.form.datetime_of_action).format(
        DEFAULT_BACKEND_DATETIME_FORMAT
      ),
    };
  }

  protected setFormValues(data: IPlantDetail): void {
    data = {
      ...data,
      datetime_of_action: new Date(String(data.datetime_of_action)),
    };

    if (data.pot_size) {
      this.potShape = this.getPotShapeBySizeString(data.pot_size);
    }

    this.form = {
      ...data,
      harvested_at: data.harvested_at || null, // New property
      harvested_wet_value_g: data.harvested_wet_value_g || null, // New property
      harvested_dry_brutto_g: data.harvested_dry_brutto_g || null, // New property
      finished_dry_at: data.finished_dry_at || null, // New property
      harvested_dry_netto_g: data.harvested_dry_netto_g || null, // New property
    };
  }

  public onSubmit() {
    this.$emit("submit", this.getFormValues());
    this.clearForm();
  }

  public onAbort() {
    this.$emit("abort", this.getFormValues());
    this.clearForm();
  }

  public get plantDetailEvents(): any {
    return PLANT_DETAIL_EVENTS;
  }

  private onPotSizeDataChanged(): void {
    if (this.potShape == "round") {
      const potRadius = this.potDiameter / 2;

      this.form.pot_capacity_liters = Number(
        Number(
          (potRadius * potRadius * Math.PI * this.potHeight) / 1000
        ).toFixed(3)
      );

      this.form.pot_size = this.potDiameter + "x" + this.potHeight;
    }

    if (this.potShape === "square") {
      this.form.pot_capacity_liters = Number(
        ((this.potHeight * this.potWidth * this.potLength) / 1000).toFixed(3)
      );
      this.form.pot_size = [this.potWidth, this.potLength, this.potHeight].join(
        "x"
      );
    }
  }

  protected clearForm(): void {
    this.form = {
      id: -1,
      pot_size: "",
      pot_capacity_liters: null,
      pot_net_weight_grams: null,
      watering_milliliters: null,
      with_fertilizer: false,
      used_fertilizer: "",
      plant_height_cm: null,
      datetime_of_action: new Date(),
      soil_type: "",
      growth_stage: "",
      event: "",
      event_info: "",
      plant_id: null,
      harvested_at: null, // New property
      harvested_wet_value_g: null, // New property
      harvested_dry_brutto_g: null, // New property
      finished_dry_at: null, // New property
      harvested_dry_netto_g: null, // New property
    };

    this.potHeight = 0;

    this.potDiameter = 0;

    this.potWidth = 0;

    this.potLength = 0;
  }

  protected get orderedPlantDetailGrowthStages() {
    return GROWTH_STAGES;
  }

  protected get isFillFormButtonVisible(): boolean {
    return this.form.event !== "";
  }

  protected setRecentPlantDetailEventData(): void {
    const plantId = this.getPlantId();

    if (plantId && this.form.event) {
      const data: any = this.fetchRecentEventActionData(
        plantId,
        this.form.event,
        (data: any) => {
          if (data) {
            this.form = data;
          }
        }
      );
    }
  }
}


import { Component } from "vue-property-decorator";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Plant } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { ON_ARCHIVE_SUCCESS, ON_REMOVE_SUCCESS } from "@/config/Events";
import { bus } from "@/init/_bus";
import { IPlant } from "@/interfaces/models/IPlant";
import AuthService from "../../../services/AuthService";
import ArchiveButton from "@/components/buttons/table_actions/ArchiveButton.vue";
import DeArchiveButton from "@/components/buttons/table_actions/DeArchiveButton.vue";

@Component({
  computed: {
    AuthService() {
      return AuthService;
    },
  },
  components: { DeArchiveButton, ArchiveButton, DefaultLayout },
  mixins: [ViewMixin],
})
export default class PlantView extends mixins(ViewMixin, PlantStoreMixin) {
  public name = "PlantView";

  protected resource = Plant.resource;
  protected descriptionField = "plant";
  protected redirectRouteNameOnDeleteSuccess = "PlantMyPlants";

  protected onAdd(): void {
    this.$router.push({ name: "PlantCreate" });
  }

  public async removeAction(options: any): Promise<any> {
    return this.delPlantItemAction(options);
  }

  protected onRemoveSuccess(response: any, resourceName: string): void {
    bus.$emit(ON_REMOVE_SUCCESS, { resource: resourceName });
  }

  protected onArchiveSuccess(response: any, resourceName: string): void {
    bus.$emit(ON_ARCHIVE_SUCCESS, { resource: resourceName });
  }

  protected onArchive(plantId: number): void {
    this.updatePlantItemAction({
      resource: this.resource,
      id: plantId,
      extraPath: "archive",
    }).then((response: any) => {
      this.onArchiveSuccess(response, "#" + String(plantId));
    });
  }

  protected onDeArchive(plantId: number): void {
    this.updatePlantItemAction({
      resource: this.resource,
      id: plantId,
      extraPath: "dearchive",
    });
    /*.then((response: any) => {
      this.onArchiveSuccess(response, "#" + String(plantId));
    });*/
  }
}

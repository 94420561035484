
import { Component } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import { Plant, PlantImage } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import PlantCard from "@/components/modules/plant/PlantCard.vue";
import PlantImageUpload from "@/components/media/PlantImage/PlantImageUpload.vue";
import GrowthStagesSteps from "@/components/modules/plant/GrowthStagesSteps.vue";
import { DEFAULT_DATE_TIME_FORMAT } from "@/config/Formats";
import { ARCHIVE, DEFAULT_ICON_SET } from "@/config/FaIcon";
import { EVENT } from "@/config/Consts";

@Component({
  methods: {
    ARCHIVE() {
      return ARCHIVE;
    },
    DEFAULT_ICON_SET() {
      return DEFAULT_ICON_SET;
    },
    DEFAULT_DATE_TIME_FORMAT() {
      return DEFAULT_DATE_TIME_FORMAT;
    },
  },
  computed: {
    PlantImage() {
      return PlantImage;
    },
  },
  components: { GrowthStagesSteps, PlantImageUpload, PlantCard },
  mixins: [UpdateMixin],
})
export default class PlantShow extends mixins(UpdateMixin, PlantStoreMixin) {
  protected name = "PlantShow.vue";

  protected isPlantImageUploadModelOpened = false;

  protected init(): void {
    this.loadPlantItem();
    this.$eventService.listen(EVENT.PLANT_ARCHIVED, () => {
      this.loadPlantItem();
    });
  }

  protected loadPlantItem(): void {
    this.findOnePlantItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return Plant.resource;
  }

  public get descriptionField(): string {
    return "plant";
  }

  protected onPlantImageUploadClick(): void {
    this.isPlantImageUploadModelOpened = true;
  }
}


import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import AppConfig from "@/config/AppConfig";
import PlantForm from "@/views/GSSApp/Plant/PlantForm.vue";
import { Plant } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";

const PlantModule = namespace(AppConfig.USER_STORE);

@Component({
  components: { PlantForm },
  mixins: [CreateMixin],
})
export default class PlantCreate extends mixins(CreateMixin, PlantStoreMixin) {
  protected name = "PlantCreate.vue";

  public get resource(): string {
    return Plant.resource;
  }

  public get descriptionField(): string {
    return "plant";
  }

  public redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "PlantMyPlants",
    });
  }

  public async createItemAction(options: ICrudOptions): Promise<any> {
    return this.createPlantItemAction(options);
  }
}

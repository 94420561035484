
import { Component, Vue } from "vue-property-decorator";
import { HomeDashboard } from "@/config/Modules";

@Component({
  components: {},
})
export default class Dashboard extends Vue {
  protected dashboardData: any = null;

  protected get resource(): string {
    return "/" + HomeDashboard.resource;
  }

  created() {
    this.fetchDashboardData();
  }

  protected fetchDashboardData() {
    this.$apiService.get(this.resource).then((response: any) => {
      console.debug(
        "Dashoard.vue fetchDashbaordData:",
        response,
        response.data
      );
      this.dashboardData = response.data;
    });
  }
}

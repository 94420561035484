
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { Plant } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IPlant } from "@/interfaces/models/IPlant";

@Component({
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class PlantList extends mixins(ListMixin, PlantStoreMixin) {
  public name = "PlantList.vue";

  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "plant" })
  public descriptionField!: string;

  public get resource(): string {
    return Plant.resource;
  }

  protected loadList(options: ICrudOptions): void {
    this.findAllPlantItemsAction(options);
  }

  protected onRowClick(row: IPlant): void {
    if (row.id) {
      this.$router.push({ name: "PlantShow", params: { id: String(row.id) } });
    }
  }

  public init() {}
}

import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Plant } from "@/config/Modules";
import { orderedPlantDetails } from "@/store/plant/getters";

const PlantModule = namespace(Plant.store);

@Component({})
export class PlantStoreMixin extends Vue {
  /**
   * Actions
   */
  @PlantModule.Action("create")
  protected createPlantItemAction: any;

  @PlantModule.Action("findOne")
  protected findOnePlantItemAction: any;

  @PlantModule.Action("update")
  protected updatePlantItemAction: any;

  @PlantModule.Action("findAll")
  protected findAllPlantItemsAction: any;

  @PlantModule.Action("resetSave")
  protected resetSavePlantAction: any;

  @PlantModule.Action("resetList")
  protected resetListPlantAction: any;

  @PlantModule.Action("del")
  protected delPlantItemAction: any;

  @PlantModule.Action("findAllSubresource")
  protected findAllSubresourcePlantItemsAction: any;

  @PlantModule.Action("setDataList")
  protected setDataListPlantAction: any;

  @PlantModule.Action("upload")
  protected uploadPlantAction: any;

  /**
   * Getters
   */
  @PlantModule.Getter("getError")
  protected getPlantError: any;

  @PlantModule.Getter("getIsLoading")
  protected getPlantIsLoading: any;

  @PlantModule.Getter("getIsSaving")
  protected getPlantIsSaving: any;

  @PlantModule.Getter("getDataItem")
  protected getPlantItem: any;

  @PlantModule.Getter("getSuccess")
  protected getPlantSuccess: any;

  @PlantModule.Getter("getDataList")
  protected getPlantList: any;

  @PlantModule.Getter("getTotal")
  protected getPlantTotal: any;

  @PlantModule.Getter("getDeletedSuccess")
  protected getPlantDeletedSuccess: any;

  @PlantModule.Getter("getValidationErrors")
  protected getPlantValidationErrors: any;

  /**
   * Mutations
   */
  @PlantModule.Mutation("IS_LOADING")
  protected setPlantLoading: any;

  @PlantModule.Mutation("SET_IS_SAVING")
  protected setPlantIsSaving: any;

  @PlantModule.Mutation("SET_ERROR")
  protected setPlantError: any;

  @PlantModule.Mutation("SET_SUCCESS")
  protected setPlantSuccess: any;

  @PlantModule.Mutation("SET_DATA_ITEM")
  protected setPlantItem: any;

  @PlantModule.Mutation("SET_DATA_LIST")
  protected setPlantList: any;

  @PlantModule.Mutation("SET_TOTAL")
  protected setPlantTotal: any;

  @PlantModule.Mutation("SET_DELETED_SUCCESS")
  protected setPlantDeletedSuccess: any;

  @PlantModule.Mutation("SET_VALIDATION_ERRORS")
  protected setPlantValidationErrors: any;

  // Additional...
  @PlantModule.Action("findAllImages")
  protected findAllPlantImages: any;

  @PlantModule.Getter("getImages")
  protected getPlantImages: any;

  @PlantModule.Mutation("SET_IMAGES")
  protected setPlantImages: any;

  @PlantModule.Getter("getIsImagesLoading")
  protected getIsPlantImagesLoading: any;

  @PlantModule.Mutation("SET_IMAGES_LOADING")
  protected setPlantImagesLoading: any;

  @PlantModule.Getter("orderedPlantDetails")
  protected getPlantOrderedPlantDetails: any;
}

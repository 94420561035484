import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const AuthModule = namespace("auth");

@Component({})
export class AuthStoreMixin extends Vue {
  /**
   * Actions
   */
  @AuthModule.Action("loginWith")
  protected loginWithAuthAction: any;

  @AuthModule.Action("sendPasswordResetLink")
  protected sendPasswordResetLinkAction: any;

  @AuthModule.Action("resetPassword")
  protected resetPasswordAction: any;

  /**
   * Getters
   */
  @AuthModule.Getter("getError")
  protected getAuthError: any;

  @AuthModule.Getter("getValidationErrors")
  protected getAuthValidationErrors: any;

  @AuthModule.Getter("getLoginMessage")
  protected getAuthLoginMessage!: string | null;

  @AuthModule.Getter("getIsLoading")
  protected getAuthIsLoading!: boolean;

  /**
   * Mutations
   */
  @AuthModule.Mutation("IS_LOADING")
  protected setAuthLoading: any;
}

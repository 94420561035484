
import { Component, Prop } from "vue-property-decorator";
import Str from "@/helper/Str";
import { mixins } from "vue-class-component";
import { HandlesUploads } from "@/components/media/mixin/HandlesUploads";
import { EVENT, MIME_TYPES } from "@/config/Consts";
import { HandlesMultiUploads } from "@/components/media/mixin/HandlesMultiUploads";
import PlantImageUploadItem from "@/components/media/PlantImage/PlantImageUpload/ImageGallery/PlantImageUploadItem.vue";
import IPlantImageMedia from "@/interfaces/others/IMedias/IPlantImageMedia";
import { eventBus } from "@/main";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: { PlantImageUploadItem },
  mixins: [HandlesUploads],
})
export default class ImageViewAndUpload extends mixins(HandlesMultiUploads) {
  protected isHovered = false;

  @Prop({ required: true })
  protected plantId!: number;

  @Prop({
    required: false,
    default: () => {
      return MIME_TYPES.filter((mime: any) => {
        return (
          mime.mime.indexOf("image/") > -1 && mime.mime.indexOf("/webp") < 0
        );
      })
        .map((mime: any) => {
          return mime.mime;
        })
        .join(",");
    },
  })
  protected accept!: string;

  protected getFileUrls(fileIdx: number): string {
    if (this.files[fileIdx]) {
      return window.URL.createObjectURL(new Blob([this.files[fileIdx]]));
    }

    if (this.media?.preview_url) {
      return this.media.preview_url;
    }

    if (this.media?.original_url) {
      return this.media.original_url;
    }
    return "https://picsum.photos/400/300";
  }

  protected onPlantImageUploadItemUploaded(
    data: IPlantImageMedia,
    fileIdx: number
  ): void {
    this.onRemoveFile(fileIdx);
  }

  protected onIsFeaturedSet(e: any, fileIdx: number): void {
    eventBus.$emit(EVENT.IMAGE_IS_FEATURED_CHANGE, fileIdx);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('DefaultForm',{attrs:{"buttons-right":false,"is-saving":_vm.getPlantIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('InfoTable',{staticClass:"mb-3",attrs:{"info-data":_vm.fileData,"base-class":''}}),_c('hr'),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
            _vm.$t(
              'components.modules.media.plant_image.plant_image_upload_item_form.labels.is_public'
            )
          ),"type":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.is_public
            ? 'is-danger'
            : '',"message":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.is_public
            ? _vm.getPlantValidationErrors.is_public
            : String(
                _vm.$t(
                  'components.modules.media.plant_image.plant_image_upload_item_form.messages.is_public'
                )
              )}},[_c('div',{staticClass:"is-fullwidth has-text-right pr-6"},[_c('b-radio',{attrs:{"type":"is-success is-light","native-value":1},model:{value:(_vm.form.is_public),callback:function ($$v) {_vm.$set(_vm.form, "is_public", $$v)},expression:"form.is_public"}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.yes")))])]),_c('b-radio',{attrs:{"type":"is-primary is-light","native-value":0},model:{value:(_vm.form.is_public),callback:function ($$v) {_vm.$set(_vm.form, "is_public", $$v)},expression:"form.is_public"}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.no")))])])],1)]),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
            _vm.$t(
              'components.modules.media.plant_image.plant_image_upload_item_form.labels.is_featured'
            )
          ),"type":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.is_featured
            ? 'is-danger'
            : '',"message":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.is_featured
            ? _vm.getPlantValidationErrors.is_featured
            : String(
                _vm.$t(
                  'components.modules.media.plant_image.plant_image_upload_item_form.messages.is_featured'
                )
              )}},[_c('div',{staticClass:"is-fullwidth has-text-right pr-6"},[_c('b-radio',{attrs:{"type":"is-success is-light","native-value":1},model:{value:(_vm.form.is_featured),callback:function ($$v) {_vm.$set(_vm.form, "is_featured", $$v)},expression:"form.is_featured"}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.yes")))])]),_c('b-radio',{attrs:{"type":"is-primary is-light","native-value":0},model:{value:(_vm.form.is_featured),callback:function ($$v) {_vm.$set(_vm.form, "is_featured", $$v)},expression:"form.is_featured"}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.no")))])])],1)]),(_vm.getPlantValidationErrors?.file)?_c('div',{staticClass:"mt-1"},[_c('b-notification',{attrs:{"type":"is-warning is-light"}},_vm._l((_vm.getPlantValidationErrors.file),function(error,errorIdx){return _c('div',{key:'plantimageuploaditemform-validation-errors-file--' + errorIdx},[_vm._v(" "+_vm._s(error)+" ")])}),0)],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue } from "vue-property-decorator";
import ILoginData from "@/interfaces/ILoginData";

@Component({
  components: {},
})
export default class LoginForm extends Vue {
  protected name = "LoginForm";

  @Prop({ required: true })
  protected validationErrors!: any;

  @Prop({ required: false, default: null })
  protected loginMessage!: string | null;

  @Prop({ required: false, default: false })
  protected loading!: boolean;

  protected getValidationErrors(): any {
    return this.validationErrors;
  }

  protected email = "";
  protected password = "";

  protected get formValues(): ILoginData {
    return {
      email: this.email,
      password: this.password,
    };
  }

  protected onSubmit(): void {
    console.log("submit now ...", this.$route.query);
    this.$emit("submit", this.formValues);
  }
}


import { Component, Vue } from "vue-property-decorator";
import LoginWithBitbucketButton from "@/components/login_with/LoginWithBitbucketButton.vue";
import { mixins } from "vue-class-component";
import { AuthStoreMixin } from "@/mixins/Stores/Auth/AuthStoreMixin";
import AppConfig from "@/config/AppConfig";

@Component({
  components: { LoginWithBitbucketButton },
})
export default class LoginWith extends mixins(AuthStoreMixin) {
  protected onLoginWith(provider: string): void {
    location.replace(
      "https://" +
        [AppConfig.ENTRYPOINT, "api", "login-with", provider].join("/")
    );
    /*
    this.$apiService
      .get(
        "https://" +
          [AppConfig.ENTRYPOINT, "api", "login-with", provider].join("/")
      )
      .then((response: any) => {
        // --
      })
      .catch((error: any) => {
        console.error("error", error);
      });
    /*

    
    this.loginWithAuthAction({
      data: {
        provider: provider,
      },
    });
    
     */
  }
}

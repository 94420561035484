
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { IPlant } from "@/interfaces/models/IPlant";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from "@/config/Formats";
import EditButton from "@/components/buttons/EditButton.vue";
import DeleteButton from "@/components/buttons/table_actions/DeleteButton.vue";
import ArchiveButton from "@/components/buttons/table_actions/ArchiveButton.vue";
import InfoTable, { IInfoData } from "@/components/common/InfoTable.vue";
import GrowthStagesSteps from "@/components/modules/plant/GrowthStagesSteps.vue";

@Component({
  methods: {
    DEFAULT_DATE_FORMAT() {
      return DEFAULT_DATE_FORMAT;
    },
  },
  components: {
    GrowthStagesSteps,
    InfoTable,
    ArchiveButton,
    DeleteButton,
    EditButton,
  },
})
export default class PlantCard extends Vue {
  @Prop({ required: true })
  protected plant!: IPlant;

  @Prop({ required: false, default: false })
  protected isSelectModeActive!: boolean;

  @PropSync("selected", { required: false, default: false })
  protected isSelected!: boolean;

  protected additionalProperties: Record<string, any> = {};

  protected isHovered = false;

  @Watch("plant", { immediate: true, deep: true })
  protected onPlantChanged(plant: IPlant | null): void {
    if (plant) {
      this.setAdditionalProperties();
    }
  }

  protected setAdditionalProperties() {
    this.additionalProperties = {
      pot_size: this.plant.pot_size,
      pot_capacity_liters: this.plant.pot_capacity_liters,
      pot_net_weight_grams: this.plant.pot_net_weight_grams,
      pot_type: this.plant.pot_type,
      watering_milliliters: this.plant.watering_milliliters,
      used_fertilizer: this.plant.used_fertilizer,
      soil_type: this.plant.soil_type,
    };
  }

  protected get plantInfoData(): Array<IInfoData> {
    const floweringDay: number = this.$moment(new Date()).diff(
      this.$moment(new Date(this.plant?.growthstage_flowering_at ?? "")),
      "days"
    );

    return [
      {
        label: String(this.$t("modules.plant_details.day_of_flowering")),
        text: String(
          this.plant?.growthstage_flowering_at
            ? floweringDay +
                " (" +
                this.$t("modules.plant_details.nth_week", {
                  week: Math.floor(floweringDay / 7) + 1,
                }) +
                ")"
            : "-"
        ),
      },
      {
        label: String(this.$t("modules.plant.recent_watering_at")),
        text: String(
          this.plant?.recent_watering_at
            ? this.$moment(new Date(this.plant.recent_watering_at)).fromNow() +
                " (" +
                (this.plant?.recent_watering_milliliters ?? 0) / 1000 +
                "L)"
            : "-"
        ),
      },
    ];
  }
}

import auth from "@/middlewares/AuthMiddleware";
import { Plant, PlantDetail } from "@/config/Modules";

// plants/create
// plants
export default [
  {
    path: "plants",
    component: () =>
      import(
        /* webpackChunkName: "plants" */ "../../views/GSSApp/Plant/PlantView.vue"
      ),
    meta: {
      title: "Plants",
      middleware: [auth],
    },
    children: [
      {
        path: "create",
        name: "PlantCreate",
        component: () =>
          import(
            /* webpackChunkName: "plant-create" */ "../../views/GSSApp/Plant/PlantCreate.vue"
          ),
        meta: {
          title: "Plant Create",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.plant.my_plants)",
              name: "PlantMyPlants",
            },
            { title: "trans(general.create)", name: "PlantCreate" },
          ],
        },
      },
      {
        path: "list",
        name: "PlantList",
        component: () =>
          import(
            /* webpackChunkName: "plant-list" */ "../../views/GSSApp/Plant/PlantList.vue"
          ),
        meta: {
          title: "Plant List",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.plant.my_plants)",
              name: "PlantMyPlants",
            },
          ],
        },
      },
      {
        path: "my",
        name: "PlantMyPlants",
        component: () =>
          import(
            /* webpackChunkName: "plant-my-plants" */ "../../views/GSSApp/Plant/PlantMyPlants.vue"
          ),
        meta: {
          title: "My Plants",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.plant.my_plants)",
              name: "PlantMyPlants",
            },
          ],
        },
      },

      {
        path: ":id",
        name: "PlantUpdate",
        component: () =>
          import(
            /* webpackChunkName: "plant-update" */ "../../views/GSSApp/Plant/PlantUpdate.vue"
          ),
        meta: {
          title: "Plant Update",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.plant.my_plants)",
              name: "PlantMyPlants",
            },
            {
              vuex: {
                module: Plant.store,
                getter: "getDataItem",
                column: "plant",
              },
              name: "PlantUpdate",
            },
          ],
        },
      },
      {
        path: ":id/show",
        name: "PlantShow",
        //redirect: { name: "PlantDetailList" },
        component: () =>
          import(
            /* webpackChunkName: "plant-show" */ "../../views/GSSApp/Plant/PlantShow.vue"
          ),
        meta: {
          title: "Plant Show",
          middleware: [auth],
          breadcrumbs: [
            {
              title: "trans(breadcrumbs.plant.my_plants)",
              name: "PlantMyPlants",
            },
            {
              vuex: {
                module: Plant.store,
                getter: "getDataItem",
                column: "plant",
              },
              name: "PlantShow",
            },
          ],
        },
        children: [
          {
            path: "",
            name: "PlantDefault",
            component: () =>
              import(
                /* webpackChunkName: "plant-show-default" */ "../../views/GSSApp/Plant/PlantShow/Default.vue"
              ),
            meta: {
              title: "PlantDefault",
              middleware: [auth],
              breadcrumbs: [
                {
                  title: "trans(breadcrumbs.plant.my_plants)",
                  name: "PlantMyPlants",
                },
                {
                  vuex: {
                    module: Plant.store,
                    getter: "getDataItem",
                    column: "plant",
                  },
                  name: "PlantShow",
                },
              ],
            },
          },
          {
            path: "detail/add",
            name: "PlantDetailAdd",
            component: () =>
              import(
                /* webpackChunkName: "plant-detail-add" */ "../../views/GSSApp/Plant/PlantDetail/PlantDetailCreate.vue"
              ),
            meta: {
              title: "PlantDetailCreate",
              middleware: [auth],
              breadcrumbs: [
                {
                  title: "trans(breadcrumbs.plant.my_plants)",
                  name: "PlantMyPlants",
                },
                {
                  vuex: {
                    module: Plant.store,
                    getter: "getDataItem",
                    column: "plant",
                  },
                  name: "PlantShow",
                },
                {
                  title: "trans(breadcrumbs.plant.detail_add)",
                  name: "PlantDetailAdd",
                },
              ],
            },
          },
          {
            path: "detail",
            name: "PlantDetailList",
            component: () =>
              import(
                /* webpackChunkName: "plant-detail-list" */ "../../views/GSSApp/Plant/PlantDetail/PlantDetailList.vue"
              ),
            meta: {
              title: "PlantDetailList",
              middleware: [auth],
              breadcrumbs: [
                {
                  title: "trans(breadcrumbs.plant.my_plants)",
                  name: "PlantMyPlants",
                },
                {
                  vuex: {
                    module: Plant.store,
                    getter: "getDataItem",
                    column: "plant",
                  },
                  name: "PlantShow",
                },
                {
                  title: "trans(breadcrumbs.plant.detail_list)",
                  name: "PlantDetailList",
                },
              ],
            },
          },
          {
            path: "detail/:detail_id",
            name: "PlantDetailUpdate",
            component: () =>
              import(
                /* webpackChunkName: "plant-detail-update" */ "../../views/GSSApp/Plant/PlantDetail/PlantDetailUpdate.vue"
              ),
            meta: {
              title: "PlantDetailUpdate",
              middleware: [auth],
              breadcrumbs: [
                {
                  title: "trans(breadcrumbs.plant.my_plants)",
                  name: "PlantMyPlants",
                },
                {
                  vuex: {
                    module: Plant.store,
                    getter: "getDataItem",
                    column: "plant",
                  },
                  name: "PlantShow",
                },
                {
                  vuex: {
                    module: PlantDetail.store,
                    getter: "getDataItem",
                    column: "datetime_of_action",
                  },
                  name: "PlantDetailUpdate",
                },
              ],
            },
          },
          {
            path: "image",
            name: "PlantImageView",
            component: () =>
              import(
                /* webpackChunkName: "plant-image-view" */ "../../views/GSSApp/Plant/PlantImage/PlantImageView.vue"
              ),
            meta: {
              title: "PlantImageView",
              middleware: [auth],
              breadcrumbs: [
                {
                  title: "trans(breadcrumbs.plant.my_plants)",
                  name: "PlantMyPlants",
                },
                {
                  vuex: {
                    module: Plant.store,
                    getter: "getDataItem",
                    column: "plant",
                  },
                  name: "PlantShow",
                },
                {
                  title: "trans(breadcrumbs.plant.image)",
                  name: "PlantImageView",
                },
              ],
            },
          },
        ],
      },
    ],
  },
];

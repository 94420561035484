var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NotAuthenticatedLayout',[_c('section',{staticClass:"section"},[_c('form',{on:{"submit":_vm.onSubmit}},[_c('b-notification',{attrs:{"type":"is-info is-light","closable":false,"active":true}},[_vm._v(" "+_vm._s(_vm.$t("modules.auth.password_reset.password_reset_form_info_text"))+" ")]),_c('b-field',{attrs:{"label":String(_vm.$t('general.email')),"type":_vm.getAuthValidationErrors && _vm.getAuthValidationErrors.email
            ? 'is-danger'
            : '',"message":_vm.getAuthValidationErrors && _vm.getAuthValidationErrors.email
            ? _vm.getAuthValidationErrors.email
            : ''}},[_c('span',{staticClass:"is-italic"},[_vm._v(_vm._s(_vm.email))])]),_c('b-field',{attrs:{"label":String(_vm.$t('general.password')),"type":_vm.getAuthValidationErrors && _vm.getAuthValidationErrors.password
            ? 'is-danger'
            : '',"message":_vm.getAuthValidationErrors && _vm.getAuthValidationErrors.password
            ? _vm.getAuthValidationErrors.password
            : ''}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","placeholder":String(_vm.$t('placeholder.password')),"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('b-field',{attrs:{"label":String(_vm.$t('general.password_confirmation')),"type":_vm.getAuthValidationErrors &&
          _vm.getAuthValidationErrors.password_confirmation
            ? 'is-danger'
            : ''}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","placeholder":String(_vm.$t('placeholder.password')),"required":""},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1),_c('b-button',{staticClass:"button is-primary is-pulled-left",attrs:{"type":"submit","loading":_vm.getAuthIsLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm._f("capitalize")(String(_vm.$t("modules.auth.buttons.send_reset_link_mail")))))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_REMOVING,
  SET_IS_SAVING,
} from "@/store/crud/functions/mutations";
import IImage from "@/interfaces/IImage";

export function SET_TOKEN(state: State, token: any) {
  state.token = token;
}

export function SET_ACCESS_TOKEN(state: State, accessToken: string) {
  state.accessToken = accessToken;
}

export function CLEAR_AUTHENTICATION_DATA(state: State): void {
  state.token = {};
  state.accessToken = null;
}

export function SET_LANGUAGE(state: State, language: string): void {
  state.language = language;
}

export function SET_IMAGE_DATA(state: State, imageData: IImage) {
  state.dataItem.image = imageData;
}

export function SET_LOGIN_MESSAGE(state: State, msg: string) {
  state.loginMessage = msg;
}

export default <MutationTree<State>>{
  SET_IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DELETED_SUCCESS,
  SET_TOKEN,
  SET_ACCESS_TOKEN,
  CLEAR_AUTHENTICATION_DATA,
  SET_VALIDATION_ERRORS,
  SET_IS_LIST_LOADING,
  SET_IS_REMOVING,
  SET_IS_SAVING,
  SET_IMAGE_DATA,
  SET_LANGUAGE,
  SET_LOGIN_MESSAGE,
};

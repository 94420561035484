
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import IMedia from "@/interfaces/IMedia";
import PlantDetailMixin from "@/mixins/Modules/Plant/PlantDetailMixin";
import { PlantImage } from "@/config/Modules";

@Component({
  components: {},
})
export default class GalleryItem extends mixins(
  PlantStoreMixin,
  PlantDetailMixin
) {
  @Prop({ required: true })
  protected image!: IMedia;

  protected isLoading = true;

  protected isError = false;

  protected setFeaturedImage(image: IMedia) {
    console.debug("setFeaturedImagae", image);
    // Add confirm dialog here
    this.$apiService.put(
      [
        PlantImage.resource(this.getPlantItem.id),
        image.uuid,
        "set-featured",
      ].join("/"),
      { data: image }
    );
  }

  protected debugTest(e: any, state: any) {
    console.debug("GalleryItem", e, state);
    this.isLoading = false;
  }
}

import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PlantDetail } from "@/config/Modules";

const PlantDetailModule = namespace(PlantDetail.store);

@Component({})
export class PlantDetailStoreMixin extends Vue {
  /**
   * Actions
   */
  @PlantDetailModule.Action("create")
  protected createPlantDetailItemAction: any;

  @PlantDetailModule.Action("findOne")
  protected findOnePlantDetailItemAction: any;

  @PlantDetailModule.Action("update")
  protected updatePlantDetailItemAction: any;

  @PlantDetailModule.Action("findAll")
  protected findAllPlantDetailItemsAction: any;

  @PlantDetailModule.Action("resetSave")
  protected resetSavePlantDetailAction: any;

  @PlantDetailModule.Action("resetList")
  protected resetListPlantDetailAction: any;

  @PlantDetailModule.Action("del")
  protected delPlantDetailItemAction: any;

  @PlantDetailModule.Action("findAllSubresource")
  protected findAllSubresourcePlantDetailItemsAction: any;

  @PlantDetailModule.Action("setDataList")
  protected setDataListPlantDetailAction: any;

  @PlantDetailModule.Action("upload")
  protected uploadPlantDetailAction: any;

  /**
   * Getters
   */
  @PlantDetailModule.Getter("getError")
  protected getPlantDetailError: any;

  @PlantDetailModule.Getter("getIsLoading")
  protected getPlantDetailIsLoading: any;

  @PlantDetailModule.Getter("getIsSaving")
  protected getPlantDetailIsSaving: any;

  @PlantDetailModule.Getter("getDataItem")
  protected getPlantDetailItem: any;

  @PlantDetailModule.Getter("getSuccess")
  protected getPlantDetailSuccess: any;

  @PlantDetailModule.Getter("getDataList")
  protected getPlantDetailList: any;

  @PlantDetailModule.Getter("getTotal")
  protected getPlantDetailTotal: any;

  @PlantDetailModule.Getter("getDeletedSuccess")
  protected getPlantDetailDeletedSuccess: any;

  @PlantDetailModule.Getter("getValidationErrors")
  protected getPlantDetailValidationErrors: any;

  /**
   * Mutations
   */
  @PlantDetailModule.Mutation("IS_LOADING")
  protected setPlantDetailLoading: any;

  @PlantDetailModule.Mutation("SET_IS_SAVING")
  protected setPlantDetailIsSaving: any;

  @PlantDetailModule.Mutation("SET_ERROR")
  protected setPlantDetailError: any;

  @PlantDetailModule.Mutation("SET_SUCCESS")
  protected setPlantDetailSuccess: any;

  @PlantDetailModule.Mutation("SET_DATA_ITEM")
  protected setPlantDetailItem: any;

  @PlantDetailModule.Mutation("SET_DATA_LIST")
  protected setPlantDetailList: any;

  @PlantDetailModule.Mutation("SET_TOTAL")
  protected setPlantDetailTotal: any;

  @PlantDetailModule.Mutation("SET_DELETED_SUCCESS")
  protected setPlantDetailDeletedSuccess: any;

  @PlantDetailModule.Mutation("SET_VALIDATION_ERRORS")
  protected setPlantDetailValidationErrors: any;

  // Additional...
}

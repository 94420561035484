
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EditButton extends Vue {
  protected name = "EditButton";

  @Prop({ required: false, default: false })
  public loading!: boolean;

  @Prop({ required: false, default: false })
  protected hideText!: boolean;

  @Prop({ required: false, default: false })
  protected expanded!: boolean;

  @Prop({ required: false, default: "button" })
  protected tag!: string;

  @Prop({ required: false, default: "" })
  protected to!: any;
}
